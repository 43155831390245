import React from 'react';
import reportWebVitals from './reportWebVitals';
import { createRoot } from "react-dom/client";
import Router from './Router/Router';

import { GlobalProvider } from './Context/globalContext';

import './index.css';

import { Provider } from 'react-redux'
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore' // <- needed if using firestore
import "firebase/compat/storage";
import "firebase/compat/database" // <- needed if using storage
// import 'firebase/functions' // <- needed if using httpsCallable

import { createStore, combineReducers } from 'redux'
import {
  ReactReduxFirebaseProvider,
  firebaseReducer
} from 'react-redux-firebase'
import { createFirestoreInstance, firestoreReducer } from 'redux-firestore' // <- needed if using firestore

const fbConfig = {
  apiKey: "AIzaSyDyf5kbhI5NRIQ9IM9D_kTsT3JNIWOK9do",
  authDomain: "andrades-web.firebaseapp.com",
  projectId: "andrades-web",
  storageBucket: "andrades-web.appspot.com",
  messagingSenderId: "197418555282",
  appId: "1:197418555282:web:4df9ede69f3d83682e134b",
  measurementId: "G-HG4BL41ZMX"
}

// react-redux-firebase config
const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
}

// Initialize firebase instance
firebase.initializeApp(fbConfig)

// Initialize other services on firebase instance
firebase.firestore() // <- needed if using firestore
// firebase.functions() // <- needed if using httpsCallable

// Add firebase to reducers
const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer // <- needed if using firestore
})

// Create store with reducers and initial state
const initialState = {}
const store = createStore(rootReducer, initialState)

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance // <- needed if using firestore
}

function App() {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <GlobalProvider>
          <Router />
        </GlobalProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  )
}

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
