import React  from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import SideNav from '../Components/SideNav';

// *** administrador *** //
import Login from '../Views/Index';

// users
import Usuarios from '../Views/Usuarios/Usuario';
import NuevoUsuario from "../Views/Usuarios/NuevoUsuario";
import EditarUsuario from '../Views/Usuarios/EditarUsuario';

// Categorias
import NuevaCategoria from '../Views/Categorias/NuevaCategoria';
import EditarCategoria from '../Views/Categorias/EditarCategoria';

// Productos
import Productos from '../Views/Productos/Productos';
import NuevoProducto from '../Views/Productos/NuevoProducto';
import EditarProducto from '../Views/Productos/EditarProducto';

// Mensajes
import Mensajes from '../Views/Mensajes/Mensajes';
import Mensaje from '../Views/Mensajes/Mensaje';


const Router = () => (
    <BrowserRouter>
        <Routes>
            <Route exact path="/" element={<Login />} />
        </Routes>
        <SideNav>
            <Routes>
                {/* usuarios */}
                <Route path="/usuarios" element={<Usuarios />} />
                <Route path="/nuevo-usuario" element={<NuevoUsuario />} />
                <Route path="/editar-usuario/:id" element={<EditarUsuario />} />

                {/* categorias */}
                <Route path="/nueva-categoria" element={<NuevaCategoria />} />
                <Route path="/editar-categoria/:id" element={<EditarCategoria />} />

                {/* porductos */}
                <Route path="/productos" element={<Productos />} />
                <Route path="/nuevo-producto" element={<NuevoProducto />} />
                <Route path="/editar-producto/:id" element={<EditarProducto />} />

                {/* mensajes */}
                <Route path="/mensajes" element={<Mensajes />} />
                <Route path="/ver-mensaje/:id" element={<Mensaje />} />


            </Routes>
        </SideNav>
    </BrowserRouter>
);

export default Router;