import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import { globalContext } from '../../Context/globalContext';
import useDatabase from '../../Hooks/useDatabase';


const Mensajes = props => {
    const global = useContext(globalContext);
    let setTitle = global.setTitle;
    setTitle("Mensajes");

    const [consulta, setConsulta] = useState({consulta: ""});
    useDatabase(consulta);

    const eliminar = id => {
        setConsulta({consulta:"eliminar", collection:"mensajes", id});

        setTimeout(() => {
            setConsulta({consulta: ""});
        }, 500);
    }

    return (
        <div className='contenedor-1'>
            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                <p className='titulo-1'>Todos los Mensajes</p>
            </div>

            <div>
                <table>
                    <tr className='tabla-header'>
                        <td>Nombre</td>
                        <td>Telefono</td>
                        <td>Correo</td>
                        <td>Estado</td>
                        <td>Accion</td>
                    </tr>
                    {props.mensajes ? (
                        props.mensajes.map(mensaje => (
                            <tr className='tabla-body'>
                                <td>{mensaje.nombre}</td>
                                <td>{mensaje.celular}</td>
                                <td>{mensaje.correo}</td>
                                <td>{!mensaje.estado ? (
                                    <b style={{color:"#DB0000"}}>Pendiente</b>
                                ) : (
                                    <b style={{color:"#00980C"}}>Atendido</b>
                                )}</td>
                                <td style={{display:"flex", gap:"10px"}}>
                                    <Link className='boton-azul' to={`/ver-mensaje/${mensaje.id}`}>Ver</Link>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <h1>cargando..</h1>
                    )}
                </table>
            </div>
        </div>
    );
};

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'mensajes'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        mensajes: ordered.mensajes
    }))
)(Mensajes);