import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import CryptoJS from "react-native-crypto-js";

import { globalContext } from '../../Context/globalContext';
import useDatabase from '../../Hooks/useDatabase';


const NuevoUsuario = () => {
    const global = useContext(globalContext);
    let setTitle = global.setTitle;
    setTitle("Nuevo Usuario");

    const [consulta, setConsulta] = useState({consulta: ""});
    const [state, setState] = useState({
        nombre: "",
        email: "",
        password: "",
        tipo: 0
    });
    useDatabase(consulta);
    
    const agregarUsuario = e => {
        e.preventDefault();

        let ciphertext = CryptoJS.AES.encrypt(state.password, 'Y2Fhdg==').toString();
        state.password = ciphertext;
        
        setConsulta({consulta:"agregar", collection:"usuarios", state, url:"/usuarios"});
    }

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    return (
        <div className='row contenedor-1'>
            <form onSubmit={agregarUsuario}>

                <div className='col s6'>
                    <p className='titulo-1'>Información del usuario</p>
                    <div className="input-field">
                        <input onChange={leerDato} id="nombre" name='nombre' type="text" className="validate" required />
                        <label for="nombre">Nombre de usuario:</label>
                    </div>
                    <div className="input-field">
                        <input onChange={leerDato} id="email" name='email' type="email" className="validate" required />
                        <label for="email">Correo:</label>
                    </div>
                    <div className="input-field">
                        <input onChange={leerDato} id="password" name='password' type="password" className="validate" required />
                        <label for="password">Contraseña:</label>
                    </div>
                    <div>
                        <p>
                            <label>
                                <input name="tipo" type="radio" checked />
                                <span>Administrador</span>
                            </label>
                        </p>
                    </div>
                </div>
                <div className='col s6' style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                    <button type='submit' className='boton-verde'>Agregar</button>
                    <Link className='boton-azul' to="/usuarios">Regresar</Link>
                </div>
            </form>
        </div>
    );
};

export default NuevoUsuario;