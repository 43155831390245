import React from 'react';
import './Loader.css';

const Loader = () => {
    return (
        <div className='contenedor-loader-padre'>
            <div className="container-loader">
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="circle"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
                <div className="shadow"></div>
            </div>
        </div>
    );
};

export default Loader;