import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import CryptoJS from "react-native-crypto-js";

import { globalContext } from '../../Context/globalContext';
import useDatabase from '../../Hooks/useDatabase';

import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'


const Mensaje = () => {
    const global = useContext(globalContext);
    let setTitle = global.setTitle;
    setTitle("Mensaje");

    const { id } = useParams();

    useFirestoreConnect([
        {
          collection: 'mensajes',
          doc: id
        }
    ])

    const mensaje = useSelector(
        ({ firestore: { data } }) => data.mensajes && data.mensajes[id]
    )

    const [consulta, setConsulta] = useState({consulta: ""});
    const [state, setState] = useState(false);

    useDatabase(consulta);

    const editarMensaje = e => {
        e.preventDefault();

        let editar = {...state};
        editar.estado = true;

        console.log({editar, id});

        setConsulta({consulta:"actualizar", collection:"mensajes", state:editar, id, url:"/mensajes"});

        setTimeout(() => {
            setConsulta({consulta: ""});
        }, 500);

    }

    useEffect(() => {
        if (mensaje) {
            setState({
                ...mensaje
            })
        }
    }, [mensaje]);

    return (
        <div className='row contenedor-1'>
            <div className='col s10'>
                <p className='titulo-1'>{state.nombre}</p>
                <table>
                    <tr className='tabla-header'>
                        <td>Nombre</td>
                        <td>Telefono</td>
                        <td>Correo</td>
                        <td>Estado</td>
                    </tr>
                    <tr className='tabla-body'>
                        <td>{state.nombre}</td>
                        <td>{state.celular}</td>
                        <td>{state.correo}</td>
                        <td>{!state.estado ? (
                            <b style={{color:"#DB0000"}}>Pendiente</b>
                        ) : (
                            <b style={{color:"#00980C"}}>Atendido</b>
                        )}</td>
                    </tr>
                </table>
                <div style={{marginTop:"50px"}}>
                    <p style={{color:"#707070", fontWeight:"bold", marginBottom:"0"}}>Informacion adicional</p>
                    <p style={{marginTop:"0"}}><b>{state.informacion}</b></p>
                </div>
            </div>
            <div className='col s2' style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                <button onClick={editarMensaje} className='boton-verde'>Atender</button>
                <Link className='boton-azul' to="/mensajes">Regresar</Link>
            </div>
        </div>
    );
};

export default Mensaje;