import React, { Fragment, useContext } from 'react';
import Swal from 'sweetalert2';
import { Link, useNavigate } from 'react-router-dom';

import { globalContext } from '../Context/globalContext';

import userImage from "../Images/user_icon.png";
import UserB from '../Images/UserB';
import Exit from '../Images/Exit';
import Producto from '../Images/Producto';
import Mensaje from '../Images/Mensaje';

import Loader from './Loader/Loader';


const SideNav = ({ children }) => {
    const history = useNavigate();
    const global = useContext(globalContext);
    let user = global.userGlobal;
    let title = global.title;
    let loader = global.loader;


    const cerrarSesion = () => {
        console.log("cerrar sesion");
        sessionStorage.clear();
        Swal.fire({
            title: 'Sesión Cerrada',
            text:'tu sesión fue cerrada correctamente',
            confirmButtonColor: '#2DCD22',
            confirmButtonText: 'Continuar',
            icon:'success'
        }).then(() => {
            history("/");
        })
    }

    return (
        <Fragment>
            {!loader && (
                <Loader />
            )}
            <div className='sidenav-custom'>
                <div className='sidenav-user'>
                    <img src={userImage} alt="Imagen Usuario Administrador" />
                    <div>
                        <p>{user.nombre}</p>
                        <p>{user.email}</p>
                    </div>
                </div>
                <div className='sidenav-title'>
                    <p>{title}</p>
                </div>
            </div>
            <div className='row'>
                <div style={{background:"#ffffff", paddingBottom:"50px"}} className='col s2'>
                    <Link to="/productos">
                        <p className='sidenav-opcion'><Producto/> Productos</p>
                    </Link>
                    <Link to="/usuarios">
                        <p className='sidenav-opcion'><UserB/> Usuarios</p>
                    </Link>
                    <Link to="/mensajes">
                        <p className='sidenav-opcion'><Mensaje/> Mensaje</p>
                    </Link>
                    <button className='sidenav-opcion' onClick={cerrarSesion}>
                        <Exit/> Cerrar sesión
                    </button>
                </div>
                <div className='col s10'>
                    {children}
                </div>
            </div>
        </Fragment>
    );
};

export default SideNav;