import React from "react";

const UserB = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Iconly_Bold_Profile"
      data-name="Iconly/Bold/Profile"
      width={22}
      height={22}
      viewBox="0 0 22 22"
    >
      <rect
        id="Iconly_Bold_Profile_Background_"
        data-name="Iconly/Bold/Profile (Background)"
        width={22}
        height={22}
        fill="none"
      />
      <g id="Profile" transform="translate(3.667 1.833)">
        <path
          id="Profile-2"
          data-name="Profile"
          d="M0,15.194c0-2.495,3.379-3.118,7.333-3.118,3.977,0,7.333.646,7.333,3.139s-3.378,3.118-7.333,3.118C3.357,18.333,0,17.687,0,15.194ZM2.48,4.85A4.853,4.853,0,1,1,7.333,9.7,4.835,4.835,0,0,1,2.48,4.85Z"
          fill="#bbb"
        />
      </g>
    </svg>
  );
};

export default UserB;
