import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import M from 'materialize-css';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { globalContext } from '../../Context/globalContext';
import useDatabase from '../../Hooks/useDatabase';

import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import { firestoreConnect, useFirestoreConnect } from 'react-redux-firebase';


const EditarProducto = props => {
    var elems = document.querySelectorAll('select');
    M.FormSelect.init(elems);

    const global = useContext(globalContext);
    let setTitle = global.setTitle;
    let setResult = global.setResult;
    setTitle("Editar Producto");

    const { id } = useParams();

    useFirestoreConnect([
        {
          collection: 'productos',
          doc: id
        }
    ])

    const producto = useSelector(
        ({ firestore: { data } }) => data.productos && data.productos[id]
    )

    // eslint-disable-next-line
    const [descripcion, setDescripcion] = useState("");
    const [consulta, setConsulta] = useState({consulta: ""});
    const [imagenes, setImagenes] = useState([]);
    const [urlsEliminar, setURLsEliminar] = useState([]);
    const [indexGlobalFiles, setIndexGlobalFiles] = useState(0);
    const [files, setFiles] = useState([]);
    const [state, setState] = useState({
        nombre: "",
        precio_menudeo: "",
        precio_mayoreo: "",
        categoria: "",
        galeria: []
    });

    const [ result ] = useDatabase(consulta);

    // eslint-disable-next-line
    useEffect(() => {
        setConsulta(false);

        if (result) {
            let copiaURLs = [...state.galeria];
            let indexCopia = indexGlobalFiles;

            copiaURLs.push({result});

            setState({
                ...state,
                galeria: copiaURLs
            });

            if (indexCopia === files.length){
                eliminarImagenDataBase();
            } else {
                indexCopia = indexCopia + 1;

                setIndexGlobalFiles(indexCopia);

                setTimeout(() => {
                    agregarImagen();
                },500);
            }
        }

        if (producto && !result) {
            setState({
                ...state,
                ...producto
            });
            // setImagenes(producto.galeria);
        }
        
        // eslint-disable-next-line
    }, [producto, result]);

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const leerCategoria = e => {
        const { firestore } = props;

        setState({
            ...state,
            categoria: firestore.doc(`categorias/${e.target.value}`)
        })
    }

    const leerImagen = (e, index = 0, files = [], imagenes = []) => {
        const length = e.target.files.length - 1;
        const copiaEvento = e;

        var copiaImagenes = [...imagenes];
        var copiaFiles = [...files];
        var file = e.target.files[index],
        imageType = /image.*/;

        if (!file.type.match(imageType))
        return;
        
        var reader = new FileReader();
        
        reader.onload = function(e){
            var result = e.target.result;
            copiaImagenes.push({result});
            copiaFiles.push(file);
            
            if (index === length) {
                setImagenes(copiaImagenes);
                setFiles(copiaFiles);
            } else {
                let nuevoIndex = index + 1;
                leerImagen(copiaEvento, nuevoIndex, copiaFiles, copiaImagenes);
            }
        }
        
        reader.readAsDataURL(file);
    }

    const pregunta = e => {
        if (e) 
            e.preventDefault();

        Swal.fire({
            title: '¿Esta seguro de actualizar este campo?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#2DCD22',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Continuar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                agregarImagen();
            } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire({
                    title: 'Cancelado',
                    text: 'El campo sigue intacto :)',
                    confirmButtonColor: '#2DCD22',
                    confirmButtonText: 'Continuar',
                    icon: 'error'
                }).then(() => {
                    setResult(false);
                });
            }
        });
    }

    const agregarImagen = () => {
        if (0 === files.length){
            eliminarImagenDataBase();
        } else {
            setConsulta({consulta:"subir_imagen", state, file:files[indexGlobalFiles], id});
        }
    }

    const eliminarImagenDataBase = () => {
        setConsulta({consulta:"eliminar_imagen_url", state, id, urlFiles:urlsEliminar, collection:"productos", url:"/productos"});
    }

    const eliminarImagen = index => {
        var copiaImagenes = [...imagenes],
        copiaFiles = [...files];

        copiaImagenes.splice(index, 1);
        copiaFiles.splice(index, 1);

        setImagenes(copiaImagenes);
        setFiles(copiaFiles);
    }

    const eliminarImagenLocal = index => {
        var copiaImagenes = [...state.galeria];
        var copiaURLsEliminar = [...urlsEliminar];

        copiaURLsEliminar.push(copiaImagenes[index]);
        copiaImagenes.splice(index, 1);

        setState({
            ...state,
            galeria:copiaImagenes
        });
        setURLsEliminar(copiaURLsEliminar);
    }


    return (
        <div className='row contenedor-1'>
            <form onSubmit={pregunta}>

                <div className='col s6'>
                    <p className='titulo-1'>Información del producto</p>
                    <div className="input-field">
                        <input onChange={leerDato} defaultValue={state.nombre} id="nombre" name='nombre' type="text" className="validate" required />
                        <label className='active' for="nombre">Nombre del producto:</label>
                    </div>
                    <div className="input-field">
                        <input onChange={leerDato} defaultValue={state.mercado_libre} id="mercado_libre" name='mercado_libre' type="text" className="validate" />
                        <label className='active' for="mercado_libre">Mercado Libre:</label>
                    </div>
                    <div className="input-field">
                        <p>Descripcion:</p>
                        <CKEditor
                            data={state.descripcion}
                            editor={ ClassicEditor }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setDescripcion(data);
                            } }
                        />
                    </div>
                    <div className="input-field">
                        <input onChange={leerDato} defaultValue={state.precio_menudeo} id="precio_menudeo" name='precio_menudeo' type="number" className="validate" required />
                        <label className='active' for="precio_menudeo">Precio Menudeo:</label>
                    </div>
                    <div className="input-field">
                        <input onChange={leerDato} defaultValue={state.precio_mayoreo} id="precio_mayoreo" name='precio_mayoreo' type="number" className="validate" required />
                        <label className='active' for="precio_mayoreo">Precio Mayoreo:</label>
                    </div>
                    <div className="input-field">
                        <select onChange={leerCategoria} name='categoria' required>
                            <option value="0" disabled selected>Selecciona una opcion</option>
                            {!props.categorias ? (
                                <option value="0" disabled>cargando...</option>
                            ) : (
                                props.categorias.map(categoria => (
                                    categoria.id === state.categoria.id ? (
                                        <option selected value={categoria.id} >{categoria.nombre}</option>
                                    ) : (
                                        <option value={categoria.id} >{categoria.nombre}</option>
                                    )
                                ))
                            )}
                        </select>
                        <label>Categoria:</label>
                    </div>
                    <p className='titulo-1'>Imagenes del Producto</p>
                    <div className="file-field input-field" style={{display:"flex"}}>
                        <div className="boton-azul">
                            <span>Imagen</span>
                            <input onChange={e => {leerImagen(e)}} name='imagen' accept="image/*" type="file" multiple />
                        </div>
                        <div className="file-path-wrapper" style={{width:"100%"}}>
                            <input onChange={e => {leerImagen(e)}} className="file-path validate" type="text" placeholder="Selecciona tus imagenes" multiple />
                        </div>
                    </div>
                </div>
                <div className='col s6' style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                    <button type='submit' className='boton-verde'>Actualizar</button>
                    <Link className='boton-azul' to="/productos">Regresar</Link>
                </div>
            </form>
            <div className='col s12' style={{display:"flex", flexWrap:"wrap", justifyContent:"stretch", alignItems:"flex-end", gap:"25px"}}>
                {state.galeria.map((imagen, index) => (
                    <div style={{display:"flex", alignItems:"center", flexDirection:"column", gap:"5px"}}>
                        <img src={imagen.result} style={{width:"170px"}} alt="Imagen del producto" />
                        <p>Posición ({index + 1})</p>
                        <button onClick={() => {eliminarImagenLocal(index)}} className='boton-rojo'>Eliminar Imagen</button>
                    </div>
                ))}
                {imagenes.map((imagen, index) => (
                    <div style={{display:"flex", alignItems:"center", flexDirection:"column", gap:"5px"}}>
                        <img src={imagen.result} style={{width:"170px"}} alt="Imagen del producto" />
                        <p>Posición ({index + 1 + state.galeria.length})</p>
                        <button onClick={() => {eliminarImagen(index)}} className='boton-rojo'>Eliminar Imagen</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default compose(
    firestoreConnect(props => [
        {
            collection: 'categorias'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        categorias: ordered.categorias
    }))
)(EditarProducto);