import React from "react";

const Producto = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      id="Iconly_Bold_Category"
      data-name="Iconly/Bold/Category"
      width={22}
      height={22}
      viewBox="0 0 22 22"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Iconly_Bold_Category_Background_Mask_"
            data-name="Iconly/Bold/Category (Background/Mask)"
            width={22}
            height={22}
            fill="none"
          />
        </clipPath>
      </defs>
      <rect
        id="Iconly_Bold_Category_Background_Mask_2"
        data-name="Iconly/Bold/Category (Background/Mask)"
        width={22}
        height={22}
        fill="none"
      />
      <g
        id="Iconly_Bold_Category-2"
        data-name="Iconly/Bold/Category"
        clipPath="url(#clip-path)"
      >
        <g id="Category" transform="translate(1.833 1.833)">
          <path
            id="Category-2"
            data-name="Category"
            d="M12.907,18.333a2.335,2.335,0,0,1-2.328-2.346V12.862a2.331,2.331,0,0,1,2.328-2.348H16a2.336,2.336,0,0,1,2.328,2.348v3.125A2.341,2.341,0,0,1,16,18.333Zm-10.579,0A2.341,2.341,0,0,1,0,15.987V12.862a2.336,2.336,0,0,1,2.328-2.348h3.1a2.331,2.331,0,0,1,2.328,2.348v3.125a2.335,2.335,0,0,1-2.328,2.346ZM12.907,7.819a2.33,2.33,0,0,1-2.328-2.346V2.347A2.336,2.336,0,0,1,12.907,0H16a2.341,2.341,0,0,1,2.328,2.347V5.473A2.335,2.335,0,0,1,16,7.819Zm-10.579,0A2.335,2.335,0,0,1,0,5.473V2.347A2.341,2.341,0,0,1,2.328,0h3.1A2.336,2.336,0,0,1,7.755,2.347V5.473A2.33,2.33,0,0,1,5.427,7.819Z"
            fill="#bbb"
          />
        </g>
      </g>
    </svg>
  );
};

export default Producto;
