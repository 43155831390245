import React from "react";

const Exit = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Iconly_Bold_Profile"
      data-name="Iconly/Bold/Profile"
      width={22}
      height={22}
      viewBox="0 0 22 22"
    >
      <rect
        id="Iconly_Bold_Profile_Background_"
        data-name="Iconly/Bold/Profile (Background)"
        width={22}
        height={22}
        fill="none"
      />
      <g id="Logout" transform="translate(-53.403 -730.253)">
        <path
          id="Path"
          d="M79.371,741.882a.778.778,0,0,0-.167-.251L76.9,739.325a.769.769,0,0,0-1.087,1.088l.994.994h-4.3a.769.769,0,1,0,0,1.538h4.3l-.994.994a.769.769,0,1,0,1.088,1.087L79.2,742.72a.771.771,0,0,0,.167-.838Z"
          transform="translate(-4.026 -1.948)"
          fill="#bbb"
        />
        <path
          id="Path-2"
          data-name="Path"
          d="M69.526,742.962a.854.854,0,0,0-.854.854v4.272H65.254V734.418a.855.855,0,0,0-.609-.818l-2.967-.89h6.994v4.272a.854.854,0,1,0,1.709,0v-5.127a.854.854,0,0,0-.854-.854H55.855a.761.761,0,0,0-.088.016.876.876,0,0,0-.115.021.86.86,0,0,0-.247.106c-.019.012-.042.013-.06.026s-.009.015-.016.02a.848.848,0,0,0-.227.27.712.712,0,0,0-.024.072.811.811,0,0,0-.067.2.536.536,0,0,0,0,.074c0,.017-.012.032-.012.049v17.089a.854.854,0,0,0,.687.837l8.544,1.709a.788.788,0,0,0,.167.017.855.855,0,0,0,.854-.854V749.8h4.272a.854.854,0,0,0,.854-.854v-5.127A.854.854,0,0,0,69.526,742.962Z"
          fill="#bbb"
        />
      </g>
    </svg>
  );
};

export default Exit;
