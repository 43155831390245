import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import M from 'materialize-css';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { globalContext } from '../../Context/globalContext';
import useDatabase from '../../Hooks/useDatabase';


const NuevoProducto = props => {
    const  generateRandomString = length => {
        var chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charLength = chars.length;
        var result = '';
        for ( var i = 0; i < length; i++ ) {
            result += chars.charAt(Math.floor(Math.random() * charLength));
        }
        return result;
    }

    var elems = document.querySelectorAll('select');
    M.FormSelect.init(elems);

    const global = useContext(globalContext);
    let setTitle = global.setTitle;
    setTitle("Nuevo Producto");

    const [imagenes, setImagenes] = useState([]);
    const [urls, setURLs] = useState([]);
    const [indexGlobal, setIndexGlobal] = useState(0);
    const [files, setFiles] = useState([]);


    const [id] = useState(generateRandomString(20));
    const [consulta, setConsulta] = useState({consulta: ""});
    const [descripcion, setDescripcion] = useState("");
    const [state, setState] = useState({
        nombre: "",
        precio_menudeo: "",
        precio_mayoreo: "",
        categoria: "",
        galeria: []
    });
    const [ result ] = useDatabase(consulta);
    
    // eslint-disable-next-line
    useEffect(() => {
        setConsulta(false);

        if (result) {
            if (urls.length === files.length - 1) {
                let copiaURLs = [...urls];
                copiaURLs.push({result});
                setURLs(copiaURLs);

                setTimeout(() => {
                    agregarProducto(copiaURLs);
                }, 500);
            } else {
                let copiaURLs = [...urls];
                let indexCopia = indexGlobal;

                indexCopia = indexCopia + 1;
                copiaURLs.push({result});

                setURLs(copiaURLs);
                setIndexGlobal(indexCopia);

                setTimeout(() => {
                    agregarImagen(false, indexCopia);
                },500);
            }
        }
        // eslint-disable-next-line
    }, [result]);

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const leerImagen = (e, index = 0, imagenes = [], files = []) => {
        const lenght = e.target.files.length - 1;
        const copiaEvento = e;

        var copiaImagenes = [...imagenes];
        var copiaFiles = [...files];
        var file = e.target.files[index],
        imageType = /image.*/;

        
        if (!file.type.match(imageType))
        return;
        
        var reader = new FileReader();
        
        reader.onload = function(e){
            var result = e.target.result;
            copiaImagenes.push({result});
            copiaFiles.push(file);
            
            if (index === lenght) {
                setImagenes(copiaImagenes);
                setFiles(copiaFiles);
            } else {
                let nuevoIndex = index + 1;
                leerImagen(copiaEvento, nuevoIndex, copiaImagenes, copiaFiles);
            }
        }
        
        reader.readAsDataURL(file);
    }

    const agregarImagen = (e, index) => {
        if (e) 
            e.preventDefault();
        
        setConsulta({consulta:"subir_imagen", id, state, file:files[index]});
    }

    const eliminarImagen = index => {
        var copiaImagenes = [...imagenes],
        copiaFiles = [...files];

        copiaImagenes.splice(index, 1);
        copiaFiles.splice(index, 1);

        setImagenes(copiaImagenes);
        setFiles(copiaFiles);
    }

    const agregarProducto = urlsGaleria => {
        const { firestore } = props;

        let data = {
            ...state,
            descripcion,
            categoria: firestore.doc(`categorias/${state.categoria}`),
            galeria: urlsGaleria
        }
        
        setConsulta({consulta:"agregar", collection:"productos", state: data, url:"/productos", id});
    }

    return (
        <div className='row contenedor-1'>
            <form onSubmit={e => {agregarImagen(e, 0)}}>

                <div className='col s6'>
                    <p className='titulo-1'>Información del producto</p>
                    <div className="input-field">
                        <input onChange={leerDato} id="nombre" name='nombre' type="text" className="validate" required />
                        <label for="nombre">Nombre del producto:</label>
                    </div>
                    <div className="input-field">
                        <input onChange={leerDato} id="mercado_libre" name='mercado_libre' type="text" className="validate" />
                        <label for="mercado_libre">Mercado Libre:</label>
                    </div>
                    <div className="input-field">
                        <p>Descripcion:</p>
                        <CKEditor
                            editor={ ClassicEditor }
                            onChange={ ( event, editor ) => {
                                const data = editor.getData();
                                setDescripcion(data);
                            } }
                        />
                    </div>
                    <div className="input-field">
                        <input onChange={leerDato} id="precio_menudeo" name='precio_menudeo' type="number" className="validate" required />
                        <label for="precio_menudeo">Precio Menudeo:</label>
                    </div>
                    <div className="input-field">
                        <input onChange={leerDato} id="precio_mayoreo" name='precio_mayoreo' type="number" className="validate" required />
                        <label for="precio_mayoreo">Precio Mayoreo:</label>
                    </div>
                    <div className="input-field">
                        <select onChange={leerDato} name='categoria' required>
                            <option value="0" disabled selected>Selecciona una opcion</option>
                            {!props.categorias ? (
                                <option value="0" disabled>cargando...</option>
                            ) : (
                                props.categorias.map(categoria => (
                                    <option value={categoria.id} >{categoria.nombre}</option>
                                ))
                            )}
                        </select>
                        <label>Categoria:</label>
                    </div>
                    <p className='titulo-1'>Imagenes del Producto</p>
                    <div className="file-field input-field" style={{display:"flex"}}>
                        <div className="boton-azul">
                            <span>Imagen</span>
                            <input onChange={e => {leerImagen(e)}} name='imagen' accept="image/*" type="file" required multiple />
                        </div>
                        <div className="file-path-wrapper" style={{width:"100%"}}>
                            <input onChange={e => {leerImagen(e)}} className="file-path validate" type="text" placeholder="Selecciona tus imagenes" required multiple />
                        </div>
                    </div>
                </div>
                <div className='col s6' style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                    <button type='submit' className='boton-verde'>Agregar</button>
                    <Link className='boton-azul' to="/productos">Regresar</Link>
                </div>
            </form>
            <div className='col s12' style={{display:"flex", flexWrap:"wrap", justifyContent:"stretch", alignItems:"flex-end", gap:"25px"}}>
                {imagenes.map((imagen, index) => (
                    <div style={{display:"flex", alignItems:"center", flexDirection:"column", gap:"5px"}}>
                        <img src={imagen.result} style={{width:"170px"}} alt="Imagen del producto" />
                        <p>Posición ({index + 1})</p>
                        <button onClick={() => {eliminarImagen(index)}} className='boton-rojo'>Eliminar Imagen</button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default compose(
    firestoreConnect(props => [
        {
            collection: 'categorias'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        categorias: ordered.categorias
    }))
)(NuevoProducto);
