import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { globalContext } from '../../Context/globalContext';
import useDatabase from '../../Hooks/useDatabase';

import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'


const EditarUsuario = () => {
    const global = useContext(globalContext);
    let setTitle = global.setTitle;
    setTitle("Editar Categoria");

    const { id } = useParams();

    useFirestoreConnect([
        {
          collection: 'categorias',
          doc: id
        }
    ])

    const categoria = useSelector(
        ({ firestore: { data } }) => data.categorias && data.categorias[id]
    )

    const [consulta, setConsulta] = useState({consulta: ""});
    const [state, setState] = useState(false);

    useDatabase(consulta);

    const editarCategoria = e => {
        e.preventDefault();

        setConsulta({consulta:"actualizar", collection:"categorias", state, id, url:"/productos"});

        setTimeout(() => {
            setConsulta({consulta: ""});
        }, 500);

    }

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const leerCheck = e => {
        let copiaArea = [...state.area];
        let indexOf = copiaArea.indexOf(e.target.value);
        
        if (indexOf < 0) {
            copiaArea.push(e.target.value);
        } else {
            copiaArea.splice(indexOf, 1);
        }

        
        setState({
            ...state,
            area: copiaArea
        });
    }

    useEffect(() => {
        if (categoria) {
            setState({
                ...categoria
            })
        }
    }, [categoria]);

    return (
        <div className='row contenedor-1'>
            <form onSubmit={editarCategoria}>

                <div className='col s6'>
                    <p className='titulo-1'>Información de la Categoría</p>
                    <div className="input-field">
                        <input defaultValue={state.nombre} onChange={leerDato} id="nombre" name='nombre' type="text" className="validate" required />
                        <label className='active' for="nombre">Nombre:</label>
                    </div>
                    <div>
                        <p>
                            <label>
                                {state.area !== undefined && (
                                    state.area.indexOf("medica") >= 0 ? (
                                        <input onChange={leerCheck} name="area" value="medica" type="checkbox" checked />
                                    ) : (
                                        <input onChange={leerCheck} name="area" value="medica" type="checkbox" />
                                    )
                                )}
                                <span>Área médica</span>
                            </label>
                        </p>
                        <p>
                            <label>
                                {state.area !== undefined && (
                                    state.area.indexOf("veterinaria") >= 0 ? (
                                        <input onChange={leerCheck} name="area" value="veterinaria" type="checkbox" checked />
                                    ) : (
                                        <input onChange={leerCheck} name="area" value="veterinaria" type="checkbox" />
                                    )
                                )}
                                <span>Área veterinaria</span>
                            </label>
                        </p>
                    </div>
                </div>
                <div className='col s6' style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                    <button type='submit' className='boton-verde'>Actualizar</button>
                    <Link className='boton-azul' to="/productos">Regresar</Link>
                </div>
            </form>
        </div>
    );
};

export default EditarUsuario;