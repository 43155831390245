import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import { globalContext } from '../../Context/globalContext';
import useDatabase from '../../Hooks/useDatabase';


const Usuarios = props => {
    const global = useContext(globalContext);
    let setTitle = global.setTitle;
    setTitle("Usuarios");

    const [consulta, setConsulta] = useState({consulta: ""});
    useDatabase(consulta);

    const eliminar = id => {
        setConsulta({consulta:"eliminar", collection:"usuarios", id});

        setTimeout(() => {
            setConsulta({consulta: ""});
        }, 500);
    }

    return (
        <div name="Usuarios" className='contenedor-1'>
            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                <p className='titulo-1'>Todos los Usuarios</p>
                <Link className='boton-morado' to="/nuevo-usuario">Nuevo Usuario</Link>
            </div>

            <div>
                <table>
                    <tr className='tabla-header'>
                        <td>Nombre</td>
                        <td>Correo</td>
                        <td>Accion</td>
                    </tr>
                    {props.usuarios ? (
                        props.usuarios.map(usuario => (
                            <tr className='tabla-body'>
                                <td>{usuario.nombre}</td>
                                <td>{usuario.email}</td>
                                <td style={{display:"flex", gap:"10px"}}>
                                    <Link className='boton-azul' to={`/editar-usuario/${usuario.id}`}>Editar</Link>
                                    <button onClick={() => {eliminar(usuario.id)}} className='boton-rojo'>Eliminar</button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <h1>cargando..</h1>
                    )}
                </table>
            </div>
        </div>
    );
};

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'usuarios'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        usuarios: ordered.usuarios
    }))
)(Usuarios);