import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { globalContext } from '../../Context/globalContext';
import useDatabase from '../../Hooks/useDatabase';


const EditarCategoria = () => {
    const global = useContext(globalContext);
    let setTitle = global.setTitle;
    setTitle("Nueva Categoria");

    const [consulta, setConsulta] = useState({consulta: ""});
    const [state, setState] = useState({
        nombre: "",
        area: []
    });
    useDatabase(consulta);

    const agregarCategoria = e => {
        e.preventDefault();
        
        setConsulta({consulta:"agregar", collection:"categorias", state, url:"/productos"});
    }

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const leerCheck = e => {
        let copiaArea = [...state.area];
        let indexOf = copiaArea.indexOf(e.target.value);
        
        if (indexOf < 0) {
            copiaArea.push(e.target.value);
        } else {
            console.log("eliminar");
            copiaArea.splice(indexOf, 1);
        }

        
        setState({
            ...state,
            area: copiaArea
        });
    }

    return (
        <div className='row contenedor-1'>
            <form onSubmit={agregarCategoria}>

                <div className='col s6'>
                    <p className='titulo-1'>Información de la Categoría</p>
                    <div className="input-field">
                        <input onChange={leerDato} id="nombre" name='nombre' type="text" className="validate" required />
                        <label for="nombre">Nombre:</label>
                    </div>
                    <div>
                        <p>
                            <label>
                                <input onChange={leerCheck} name="area" value="medica" type="checkbox" />
                                <span>Área médica</span>
                            </label>
                        </p>
                        <p>
                            <label>
                                <input onChange={leerCheck} name="area" value="veterinaria" type="checkbox" />
                                <span>Área veterinaria</span>
                            </label>
                        </p>
                    </div>
                </div>
                <div className='col s6' style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                    <button type='submit' className='boton-verde'>Agregar</button>
                    <Link className='boton-azul' to="/productos">Regresar</Link>
                </div>
            </form>
        </div>
    );
};

export default EditarCategoria;