import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';

import { globalContext } from '../../Context/globalContext';
import useDatabase from '../../Hooks/useDatabase';

import editarIcon from "../../Images/editar.svg";
import eliminarIcon from "../../Images/eliminar.svg";


const Productos = props => {
    const global = useContext(globalContext);
    let setTitle = global.setTitle;
    setTitle("Productos");

    const [consulta, setConsulta] = useState({consulta: ""});
    useDatabase(consulta);

    const eliminarProducto = id => {
        setConsulta({consulta:"eliminar_producto", collection:"productos", id});
        
        setTimeout(() => {
            setConsulta({consulta: ""});
        }, 500);
    }
        
    const eliminarCategoria = id => {
        console.log(props.productos);
        let existe = false;

        for (let index = 0; index < props.productos.length; index++) {
            const element = props.productos[index];
            if (element.categoria.id === id) {
                existe = true;
            }
        }

        if (!existe) {
            setConsulta({consulta:"eliminar", collection:"categorias", id});
    
            setTimeout(() => {
                setConsulta({consulta: ""});
            }, 500);
        } else {
            Swal.fire({
                title: 'No se puede eliminar',
                text:'La categoria tiene productos, primero elimina los productos de la categoria',
                confirmButtonColor: '#2DCD22',
                confirmButtonText: 'Continuar',
                icon:'error'
            });
        }
    }

    return (
        <div name="Usuarios" className='contenedor-1'>
            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}>
                <p className='titulo-1'>Categoría de Productos</p>
                <div style={{display:"flex", gap:"10px"}}>
                    <Link className='boton-morado' to="/nueva-categoria">Nueva Categoría</Link>
                    <Link className='boton-morado' to="/nuevo-producto">Nuevo Producto</Link>
                </div>
            </div>

            <div style={{display:"flex", flexDirection:"column", gap:"50px"}}>
                {props.categorias ? (
                    props.categorias.map(categoria => (
                        <div>
                            <div style={{display:"flex", gap:"10px"}}>
                                <p className='texto-1' style={{margin:"0"}}>{categoria.nombre}</p>
                                <img onClick={() => {eliminarCategoria(categoria.id)}} src={eliminarIcon} alt="editar categoria" style={{width:"15px", height:"15px", cursor:"pointer"}} />
                                <Link to={`/editar-categoria/${categoria.id}`}>
                                    <img src={editarIcon} alt="editar categoria" style={{width:"15px", height:"15px"}} />
                                </Link>
                            </div>
                            <table>
                                <tr className='tabla-header'>
                                    <td>Nombre</td>
                                    <td>Precio Menudeo</td>
                                    <td>Precio Mayoreo</td>
                                    <td>Accion</td>
                                </tr>
                                {props.productos ? (
                                    props.productos.map(producto => (
                                        producto.categoria.id === categoria.id && (
                                            <tr className='tabla-body'>
                                                <td>{producto.nombre}</td>
                                                <td>{producto.precio_menudeo}</td>
                                                <td>{producto.precio_mayoreo}</td>
                                                <td style={{display:"flex", gap:"10px"}}>
                                                    <Link className='boton-azul' to={`/editar-producto/${producto.id}`}>Editar</Link>
                                                    <button onClick={() => {eliminarProducto(producto.id)}} className='boton-rojo'>Eliminar</button>
                                                </td>
                                            </tr>
                                        )
                                    ))
                                ) : (
                                    <h1>cargando..</h1>
                                )}
                            </table>
                        </div>
                    ))
                ) : (
                    <h1>cargando...</h1>
                )}
            </div>
        </div>
    );
};

export default compose(
    firestoreConnect(props => [ 
        {
            collection: 'productos'
        },
        {
            collection: 'categorias'
        }
    ]),
    connect(({ firestore: { ordered }}, props ) => ({
        productos: ordered.productos,
        categorias: ordered.categorias
    }))
)(Productos);