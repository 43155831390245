import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import CryptoJS from "react-native-crypto-js";

import { globalContext } from '../../Context/globalContext';
import useDatabase from '../../Hooks/useDatabase';

import { useSelector } from 'react-redux'
import { useFirestoreConnect } from 'react-redux-firebase'


const EditarUsuario = () => {
    const global = useContext(globalContext);
    let setTitle = global.setTitle;
    setTitle("Editar Usuario");

    const { id } = useParams();

    useFirestoreConnect([
        {
          collection: 'usuarios',
          doc: id
        }
    ])

    const usuario = useSelector(
        ({ firestore: { data } }) => data.usuarios && data.usuarios[id]
    )

    const [consulta, setConsulta] = useState({consulta: ""});
    const [state, setState] = useState(false);

    useDatabase(consulta);

    const editarUsuario = e => {
        e.preventDefault();
        
        let ciphertext = CryptoJS.AES.encrypt(state.password, 'Y2Fhdg==').toString();
        state.password = ciphertext;

        setConsulta({consulta:"actualizar", collection:"usuarios", state, id, url:"/usuarios"});

        setTimeout(() => {
            setConsulta({consulta: ""});
        }, 500);

    }

    const leerDato = e => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        if (usuario) {
            let bytes  = CryptoJS.AES.decrypt(usuario.password, 'Y2Fhdg==');
            let originalText = bytes.toString(CryptoJS.enc.Utf8);
    
            setState({
                ...usuario,
                password: originalText
            })
        }
    }, [usuario]);

    return (
        <div className='row contenedor-1'>
            <form onSubmit={editarUsuario}>

                <div className='col s6'>
                    <p className='titulo-1'>Información del usuario</p>
                    <div className="input-field">
                        <input onChange={leerDato} defaultValue={state.nombre} id="nombre" name='nombre' type="text" className="validate" required />
                        <label className='active' for="nombre">Nombre de usuario:</label>
                    </div>
                    <div className="input-field">
                        <input onChange={leerDato} defaultValue={state.email} id="email" name='email' type="email" className="validate" required />
                        <label className='active' for="email">Correo:</label>
                    </div>
                    <div className="input-field">
                        <input onChange={leerDato} defaultValue={state.password} id="password" name='password' type="text" className="validate" required />
                        <label className='active' for="password">Contraseña:</label>
                    </div>
                    <div>
                        <p>
                            <label>
                                <input name="tipo" type="radio" checked />
                                <span>Administrador</span>
                            </label>
                        </p>
                    </div>
                </div> 
                <div className='col s6' style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                    <button type='submit' className='boton-verde'>Actualizar</button>
                    <Link className='boton-azul' to="/usuarios">Regresar</Link>
                </div>
            </form>
        </div>
    );
};

export default EditarUsuario;