import React from "react";

const Mensaje = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            id="Iconly_Bold_Profile"
            data-name="Iconly/Bold/Profile"
            width={22}
            height={22}
            viewBox="0 0 22 22"
        >
            <defs>
                <clipPath id="clip-path">
                    <path
                        id="Trazado_12"
                        data-name="Trazado 12"
                        d="M52.418,133.832H72.9v7.761H52.418Zm0,0"
                        transform="translate(-52.418 -133.832)"
                        fill="#bbb"
                    />
                </clipPath>
                <clipPath id="clip-path-2">
                    <path
                        id="Trazado_14"
                        data-name="Trazado 14"
                        d="M52.418,252H72.9v12.32H52.418Zm0,0"
                        transform="translate(-52.418 -252)"
                        fill="#bbb"
                    />
                </clipPath>
            </defs>
            <rect
                id="Iconly_Bold_Profile_Background_"
                data-name="Iconly/Bold/Profile (Background)"
                width={22}
                height={22}
                fill="none"
            />
            <g
                id="Diseño_sin_título"
                data-name="Diseño sin título"
                transform="translate(-51.418 -130.832)"
            >
                <g
                    id="Grupo_30"
                    data-name="Grupo 30"
                    transform="translate(52.418 133.832)"
                    clipPath="url(#clip-path)"
                >
                    <path
                        id="Trazado_11"
                        data-name="Trazado 11"
                        d="M62.719,141.578l10.239-6.144v-.027c0-.052,0-.1-.008-.154s-.013-.1-.023-.153-.023-.1-.038-.15-.032-.1-.052-.146-.042-.094-.066-.14-.051-.09-.08-.133-.059-.084-.092-.124-.067-.078-.1-.114-.075-.071-.115-.1-.081-.063-.124-.092-.087-.055-.133-.08-.092-.046-.14-.066-.1-.037-.146-.052-.1-.027-.15-.038-.1-.018-.153-.023-.1-.008-.154-.008H54.056q-.077,0-.154.008t-.153.023q-.076.015-.15.038t-.146.052q-.071.03-.14.066t-.133.08q-.064.043-.124.092c-.04.033-.078.067-.115.1s-.071.075-.1.114-.063.081-.092.124-.055.087-.079.133-.046.092-.066.14-.037.1-.052.146-.027.1-.038.15-.018.1-.023.153-.007.1-.007.154v.027Zm0,0"
                        transform="translate(-52.479 -133.832)"
                        fill="#bbb"
                    />
                </g>
                <g
                    id="Grupo_31"
                    data-name="Grupo 31"
                    transform="translate(52.418 137.265)"
                    clipPath="url(#clip-path-2)"
                >
                    <path
                        id="Trazado_13"
                        data-name="Trazado 13"
                        d="M63.125,258.107a.781.781,0,0,1-.2.084.771.771,0,0,1-.1.021.794.794,0,0,1-.212,0,.771.771,0,0,1-.1-.021.794.794,0,0,1-.1-.035.778.778,0,0,1-.095-.049l-9.833-5.9v10.739c0,.052,0,.1.007.154s.013.1.023.153.023.1.038.15.032.1.052.146.042.094.066.14.051.09.079.133.059.084.092.124.067.078.1.114.075.071.115.1.081.063.124.092.087.055.133.079.092.046.14.066.1.037.146.052a1.581,1.581,0,0,0,.3.06q.077.008.154.008H71.383q.077,0,.154-.008t.153-.023a1.545,1.545,0,0,0,.15-.038q.074-.022.146-.052t.14-.066q.068-.036.133-.079t.124-.092q.06-.049.115-.1t.1-.114q.049-.06.092-.124t.08-.133q.036-.068.066-.14t.052-.146q.022-.074.038-.15t.023-.153c0-.051.008-.1.008-.154V252.207Zm0,0"
                        transform="translate(-52.479 -252.201)"
                        fill="#bbb"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Mensaje;
