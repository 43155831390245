import React, { useState } from 'react';
import CryptoJS from "react-native-crypto-js";


const globalContext = React.createContext();

const GlobalProvider = ({ children }) => {
    let originalText = "{}";
    const userLocal = sessionStorage.getItem("__user_ands__") || false;

    if (userLocal){
        let bytes  = CryptoJS.AES.decrypt(userLocal, 'andrades');
        originalText = bytes.toString(CryptoJS.enc.Utf8);
    }

    const [userGlobal, setUserGlobal] = useState(JSON.parse(originalText));
    const [title, setTitle] = useState("");
    const [loader, setLoader] = useState(true);

    return ( 
        <globalContext.Provider value={{userGlobal, setUserGlobal, title, setTitle, loader, setLoader}}>
            {children}
        </globalContext.Provider>
    );
}
 
export {GlobalProvider, globalContext};